import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/Layout";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Container } from "@chakra-ui/react";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Data Protection | THE PROJECT STATUS" urlPage="/data-protection" mdxType="SEO" />
    <Container maxW="800px" minH="640px" px={4} mdxType="Container">
      <h2 {...{
        "id": "data-protection",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#data-protection",
          "aria-label": "data protection permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Data Protection`}</h2>
      <p>{`Data protection is very important. Therefore, the use of this website is
possible without offering person-related information.`}</p>
      <p>{`This site is programmed, organized and maintained by Prof. Dr. Ulrich Anders for
the purpose of teaching at Cologne Business School, and for coaching or for
helping people or organizations to generate project status reports. As such this
site has no intention to gather and process any person-related data.`}</p>
      <p>{`However, this site uses third party tracking tools, i.e. Google Analytics to
improve this site’s performance and features. These third party tracking tools
are designed to collect only non-personal information about your use of this
Website. However, you understand that such tools are created and managed by
parties outside the control of this website. As such, the owner of this app and
website is not responsible for what information is actually captured by such
third parties or how such third parties use and protect that information.
However, in order to protect you to some degree your IP it is anonymized.`}</p>
      <p>{`Furthermore, this site does not offer newsletters or contact forms and the owner
of this site does not store any person-related information in connection to this
site.`}</p>
      <p>{`All data, that you input into the webapp stay on your personal PC only. For the
purpose of your project management data this webapp has no connection with a
central database and therefore cannot and does not store such data.`}</p>
      <h3 {...{
        "id": "hosting-and-internet-provider",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#hosting-and-internet-provider",
          "aria-label": "hosting and internet provider permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Hosting and internet provider`}</h3>
      <p>{`However, like all other websites this site is hosted by an internet provider. In
the case of this site the internet provider is
`}<a parentName="p" {...{
          "href": "https://render.com",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`render at https://render.com`}</a>{`. render is using common
internet technologies, such as cookies and web server logs for monitoring access
to this website.`}</p>
      <p>{`Most internet providers are generating log files that usually contain the
visitor’s browser type, language preference, referring site, additional websites
requested, the date and time of each visitor request and potentially
personally-identifying information like Internet Protocol (IP) addresses.`}</p>
      <p>{`The reason for doing so is to better understand how visitors use the website,
and to monitor and protect the security of the website.`}</p>
      <p>{`Please read more about `}<a parentName="p" {...{
          "href": "https://render.com/privacy",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`render’s privacy statement`}</a>{`
in order to find out about their approach to protecting data.`}</p>
      <h3 {...{
        "id": "do-not-track",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#do-not-track",
          "aria-label": "do not track permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Do not track`}</h3>
      <p>{`In general, you can prevent the analysis of user behavior by blocking the use of
cookies in your browser, for example by make an exception in the browser (such
as Firefox browser). Please look in the program help for how this can be set up
in your browser.`}</p>
      <p>{`Of course, you can also on occasion, in individual cases or periodically delete
cookies in your browser to clear tracking information. If you have activated the
so-called Do-Not-Track function (“DNT”) in your browser, then your visit to
websites will not automatically be recorded by web analysis tools. However, this
does not work with every browser. To enable DNT in your browser, please check
your browser’s help function if you do not know how to activate it.`}</p>
      <p>{`Changes to this privacy policy may occur. The current version of the privacy
policy is available at https://project-status.org/data-protection.`}</p>
      <h3 {...{
        "id": "your-own-data-collection",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#your-own-data-collection",
          "aria-label": "your own data collection permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Your own data collection`}</h3>
      <p>{`This site offers forms that help you to collect contact information about
people. Please, ensure that you are only collecting information, to which people
have given their consent. Be further aware, that these information will also be
in the parameter files, that you can download and share. As you are the sole
owner and collector of these information, please, be aware that you will fall
under the data protection laws yourself.`}</p>
      <h3 {...{
        "id": "inquires",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#inquires",
          "aria-label": "inquires permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Inquires`}</h3>
      <p>{`All inquires concerning the data protection for this website can be addressed
to:`}</p>
      <p>{`Prof. Dr. Ulrich Anders`}<br />{` Cologne Business School`}<br />{` Hardefuststr. 1`}<br />{`
D-50677 Köln`}<br />{` prof.anders@online.de`}<br /></p>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      